/**
 * @typedef {object} Event
 * @property {string} key - The event key.
 * @property {import("~/utils/types").LocalizedField} name - The localized name of the event.
 * @property {import("~/utils/types").LocalizedField} city - The localized city of the event.
 * @property {string} province - The province of the event.
 * @property {string} start_date - The start date of the event.
 * @property {string} instance_key - The instance key of the event.
 * @property {Array} days - The array of days.
 * @property {string} days.start_time - The start date of the day.
 * @property {string} days.end_time - The end date of the day.
 * @property {object} location - The location of the event.
 * @property {string} location.lat - The latitude of the location.
 * @property {string} location.lng - The longitude of the location.
 * @property {string} location.address - The address of the location.
 * @property {Array} bookings - The array of bookings.
 * @property {string} bookings.name - The name of the booking.
 * @property {string} bookings.image_url - The image URL of the booking.
 * @property {string} bookings.booking_url - The booking URL.
 */

/**
 * Fetches all events
 * @returns {Promise<Event[]>} Returns a promise that resolves with all events
 */
export const getEvents = async () => {
  const { status, body } = await $fetch("/api/events");
  if (status !== 200) {
    return [];
  }
  return body;
};

/**
 * Fetches the next or current event for a given province
 * @param {string} province
 * @returns {Promise<Event|null>} Returns a promise that resolves with the next or current event
 */
export const getNextOrCurrentEvent = async (province) => {
  const events = await getEvents();
  const now = new Date().getTime();

  // Find the next event for the given province
  const nextEvent = events
    .filter(({ province: eventProvince }) => eventProvince === province)
    .find(({ end_time }) => {
      // Check if the event end time is greater than the current time
      const eventEndTime = new Date(end_time).getTime();
      return eventEndTime > now;
    });

  return nextEvent || null;
};

export const validateIfEventOver = async (instance_key, router, store) => {
  const reset = () => {
    store.$reset();
    window.location.reload();
  };

  const events = await getEvents();
  console.log("events", events);
  if (!events.length) return reset();

  const event = events.find(({ instance_key: key }) => key === instance_key);
  if (!event) return reset();
};
